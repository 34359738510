<template>
  <div class="product-info">
    <h3>{{ product.libelle }}</h3>
    <p><strong>Type:</strong> {{ type }}</p>
    <p><strong>Catégorie:</strong> {{ categorie }}</p>
    <p><strong>Quantité:</strong> {{ product.quantity }}</p>
    <p>
      <strong>Prix:</strong> {{ product.actualAmount }} Fcfa
    </p>
    <product-price-table
      :prices="prices"
    />
    <modal
      title="Ajout du prix de vente"
      id="add-price"
      :footer="false"
    >
      <div
        class="row"
        v-if="has_error"
      >
        <div class="col-12">
          <p class="alert">
            {{ error_msg }}
          </p>
        </div>
      </div>
      <div class="form-group">
        <label for="price">Prix</label>
        <input
          class="form-control"
          name="price"
          id="price"
          v-model="amount"
        >
      </div>
      <div class="form-check-group">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="actualAmount"
            id="actualAmount"
          >
          <label
            class="form-check-label"
            for="actualAmount"
          >Prix actuel</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="principal"
            id="principal"
          >
          <label
            class="form-check-label"
            for="principal"
          >Prix principal</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="discount"
            id="discount"
          >
          <label
            class="form-check-label"
            for="discount"
          >Prix promotionnel</label>
        </div>
      </div>
      <div class="form-group text-right">
        <a
          href="javascript:void(0)"
          class="btn btn-primary btn-sm"
          @click.prevent="addProductPrice"
        >Ajouter</a>
      </div>
    </modal>
  </div>
</template>
  
  <script>
  import { ADD_PRODUCT_PRICE } from '../../../graphql/market';
import ProductPriceTable from './ProductPriceTable.vue';
import Modal from '../../modal.vue'
  import {  mapMutations } from 'vuex'
  export default {
    components: { ProductPriceTable, Modal },
    props: {
      product: { type: Object, required: true },
      type: { type: String, required: true },
      categorie: { type: String, required: true },
      prices: { type: Array, required: true }
    },
    data() {
      return {
        showPriceTable: false,
        amount: null,
        actualAmount: false,
        principal: false,
        discount: false,
        has_error: false,
        error_msg: null,
      };
    },
    methods: {
        ...mapMutations({
            setLoading: 'SET_LOADING',
            done: 'DONE'
        }),
        togglePriceTable() {
            this.showPriceTable = !this.showPriceTable;
        },
        initProductPriceForm() {
            this.amount = null;
            this.discount = false;
            this.actualAmount = false;
            this.principal = false;
        },
        addProductPrice() {
            this.setLoading(true);
            let data = {
                amount: parseFloat(this.amount),
                product: this.product.uid,
                actual: this.actualAmount,
                principal: this.principal,
                discount: this.discount
            };
            this.$apollo.mutate({
                mutation: ADD_PRODUCT_PRICE,
                variables: {
                    "price": {
                        ...data
                    }
                },
                update: (data) => {
                    console.log(`Product price add successfully on uid ${data.uid}`);
                }
            }).then(() => {
                this.initProductPriceForm();
                this.done();
            }).catch((error) => {
                this.setLoading(false);
                console.error(error);
                this.has_error = true;
                this.error_msg = error;
            });
            console.log(data);
            this.done();
        },
    }
  };
  </script>
  